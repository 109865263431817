<script context="module" lang="ts">
	import type { BorderRadius } from '$lib/components/Border.svelte';

	type TProps = {
		borderColour?: TColours | undefined;
		backgroundColour: TColours;
		to?: string | undefined;
		borderRadius?: BorderRadius;
		fullWidth?: boolean;
		disabled?: boolean;
		type?: HTMLButtonElement['type'];
		children: Snippet;
		onclick?: (event: MouseEvent) => void;
		outer?: Snippet;
		autofocus?: boolean;
		additionalClasses?: string;
		shadow?: ShadowsDP;
		'data-testid'?: string;
	};
</script>

<script lang="ts">
	import Button from '$lib/components/buttons/Button.svelte';
	import Flex from '$lib/components/Flex.svelte';
	import Padding from '$lib/components/Padding.svelte';
	import { type TColours } from '$lib/data/colours';
	import type { ShadowsDP } from '$lib/utilities/getShadowClass';
	import type { Snippet } from 'svelte';

	const {
		borderColour,
		backgroundColour,
		to,
		fullWidth,
		disabled,
		type,
		borderRadius = 2,
		children,
		outer: textOuter,
		onclick,
		autofocus,
		additionalClasses,
		shadow,
		...restProps
	}: TProps = $props();
</script>

<Button
	{fullWidth}
	colour="secondary-black"
	{to}
	{borderRadius}
	{borderColour}
	{type}
	{disabled}
	{backgroundColour}
	{onclick}
	{autofocus}
	{additionalClasses}
	{shadow}
	{...restProps}
>
	<Padding y={1.5} x={2} fullWidth>
		<Flex align="center" justify="center" fullWidth>
			{@render children()}
		</Flex>
	</Padding>

	{#snippet outer()}
		{#if textOuter}
			{@render textOuter()}
		{/if}
	{/snippet}
</Button>
